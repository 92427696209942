<template>
  <div>
    <h2>招聘信息列表</h2>
    <ul>
      <li v-for="job in jobs" :key="job.urls">
        <!-- 将查询详情链接放到标题上 -->
        <h3><a :href="job.urls" target="_blank">{{ job.title }}</a></h3>
        <p>发布日期: {{ new Date(job.date).toLocaleDateString() }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';

export default {
  name: 'RecruitmentList',
  setup() {
    const jobs = ref([]);

    onMounted(async () => {
      try {
        //const today = new Date();
        //const formattedDate = today.toISOString().split('T')[0]; // 格式为 'YYYY-MM-DD'
        //const filename = `recruitment_${formattedDate}.json`
        const filename = `recruitment_data.json`
        const response = await axios.get(`/${filename}`);
        jobs.value = response.data;
      } catch (error) {
        console.error('Failed to load recruitment data:', error);
      }
    });

    return {
      jobs
    };
  }
};
</script>

<style>
/* 在这里添加CSS样式 */
ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: #f9f9f9;
  margin-bottom: 15px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h3 a {
  color: #005792; /* 标题颜色 */
  text-decoration: none; /* 去除下划线 */
}

h3 a:hover {
  text-decoration: underline; /* 鼠标悬停时添加下划线，增加互动性 */
}

p {
  color: #666;
}

</style>
