import { createRouter, createWebHistory } from 'vue-router';
import RecruitmentList from '../components/RecruitmentList.vue';
import ChatWindow from '../components/ChatWindow.vue';

const routes = [
  {
    path: '/',
    redirect: '/recruitment' // 默认重定向到招聘信息平台
  },
  {
    path: '/recruitment',
    component: RecruitmentList
  },
  {
    path: '/chat',
    component: ChatWindow
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
