<template>
  <div id="app">
    <header>
      <nav>
        <!-- 联系方式部分 -->
      <div class="contact-info">
        <a href="weixing:+max1079944650"><i class="fas fa-phone"></i> +vx:max1079944650</a>
      </div>
        <router-link to="/recruitment" class="nav-link">招聘信息平台</router-link>
        <router-link to="/chat" class="nav-link">岗位咨询</router-link>
      </nav>
    </header>
    
    <main>
      <router-view></router-view> <!-- 根据当前路由显示对应组件 -->
    </main>
  </div>
</template>


<script>

export default {
  name: 'App'
};
</script>

<style>
/* 全局样式 */
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

header {
  background-color: #005792; /* 深蓝色背景 */
  color: #fff; /* 白色文字 */
  padding: 20px 0; /* 上下填充 */
  text-align: center; /* 文字居中 */
}

/* 导航栏样式 */
header nav {
  display: flex;
  justify-content: space-around; /* 两端对齐，中间间隔均匀分布 */
  align-items: center; /* 垂直居中 */
  padding: 0 10%; /* 两侧留出一些空间 */
}

/* 链接样式 */
header nav a {
  color: #fff; /* 白色文字 */
  text-decoration: none; /* 去掉下划线 */
  padding: 10px 20px; /* 内填充 */
  border-radius: 5px; /* 轻微圆角 */
  transition: background-color 0.3s ease; /* 平滑背景色过渡 */
}
main {
  display: flex;
  justify-content: center;
  margin: 20px;
}
/* 链接悬停和焦点样式 */
header nav a:hover,
header nav a:focus {
  background-color: #003f5c; /* 悬停时背景色变深 */
  outline: none; /* 去掉焦点时的轮廓线 */
}

/* 标题样式，减少默认的外边距，使其更紧凑 */
header h1 {
  margin: 0;
  font-size: 1.5rem; /* 适当调整字体大小 */
}

/* 适应屏幕的响应式设计 */
@media (max-width: 600px) {
  header nav {
    flex-direction: column; /* 在小屏幕上垂直排列 */
  }

  header nav a {
    margin: 5px 0; /* 增加垂直间距 */
  }
}
footer {
  background-color: #003554;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
}

/* 卡片样式 */
.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
  margin: 10px;
  width: 300px; /* 或者使用max-width来适应不同屏幕尺寸 */
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.card h3 {
  color: #005792;
}

.card p {
  color: #666;
}

/* 按钮样式 */
.button {
  background-color: #005792;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #003554;
}
</style>

