<template>
  <div class="chat-window">
    <div class="chat-header">公考选岗专家</div> 
    <div class="messages">
      <!-- 加载动画 -->
      <div v-if="isLoading" class="load">
        <hr/><hr/><hr/><hr/>
      </div>
      <div v-for="(message, index) in messages" :key="index" class="message" :class="{ 'user': message.sender === 'user', 'bot': message.sender === 'bot' }">
        <!-- 使用一个固定容器显示打字效果 -->
        <div v-if="message.sender === 'bot' && message.isTyping" class="typing" :ref="'typeitContainer' + index"></div>
        <div v-else class="message-content">{{ message.content }}</div>
        <div class="avatar">
          <!-- 根据发送者选择不同的头像 -->
          <img v-if="message.sender === 'user'" :src="userAvatar" alt="User">
          <img v-else :src="botAvatar" alt="Bot">
        </div>
      </div>
    </div>
    <div class="input-area">
      <input v-model="newMessage" @keyup.enter="sendMessage" placeholder="Type a message..." class="input-field" :disabled="isSending"/>
      <button @click="sendMessage" class="send-button" :disabled="isSending || !newMessage.trim()">发送</button> <!-- 添加发送按钮 -->
    </div>
  </div>
</template>

<script>
import TypeIt from "typeit";
import axios from "axios";
export default {
  name: 'ChatWindow',
  data() {
    return {
      messages: [],
      newMessage: '',
      isSending: false, // 控制发送状态
      isLoading: false,
      progressValue: 0, // 进度值，根据需要进行更新
      // 使用require引入图片，并赋值给数据属性
      userAvatar: require('@/assets/img/用户对话头像_rm.png'),
      botAvatar: require('@/assets/img/华公未来_rm.png'),
    };
  },
  methods: {
    async sendMessage() {
      if (!this.newMessage.trim()) return; // 确保不发送空消息
      this.isSending = true; // 开始发送，禁用发送按钮
      this.isLoading = true;
      // 添加用户消息到对话
      this.messages.push({ sender: 'user', content: this.newMessage, isTyping: false });
      try {
      // 模拟异步获取机器人回复
      setTimeout(async () => {
        const botMessageContent = await axios.post('http://192.168.110.49:5000/api/chat',  { message: this.messages[this.messages.length - 1].content });
        this.messages.push({ sender: 'bot', content: botMessageContent, isTyping: true });
        setTimeout(() => {
          const botMessageIndex = this.messages.length -1 ;
          const container = this.$refs[`typeitContainer${botMessageIndex}`];
          if (container) {
            new TypeIt(container[0], {
              strings: [botMessageContent],
              speed: 50,
              afterComplete: (instance) => {
                this.messages[botMessageIndex].isTyping = false;
                instance.destroy(); // 强制更新以显示完整消息
              }
            }).go();
          }
        },0);
        this.isLoading = false;
        // 清空输入框
        this.newMessage = '';
        this.isSending = false; // 结束发送，允许再次发送
      }, 1000); // 假设1秒后收到回复
    } catch (error) {
      console.error('获取回复失败:', error);
      // 处理错误情况，例如添加一条错误消息到对话
      this.messages.push({ sender: 'bot', content: '抱歉，无法获取回复。' });
    }
    },
  }
}
</script>


<style scoped>
.chat-header {
  padding: 10px;
  /* background-color: #007bff; */
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 20px;
}
.chat-window {
  display: flex;
  flex-direction: column;
  height: 80vh; /* Increase height to take up more vertical space */
  width: 80%; /* Make the chat window wider */
  max-width: 800px; /* Increase max width */
  margin: 20px auto; /* Center the chat window with more margin */
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fcfcfc; /* Optional: change the background color */
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px; /* Increase padding for more space around messages */
  background-color: #fcfcfc;
}

.message {
  width: 100%; /* 让消息容器占满整个宽度 */
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Slightly increase spacing between messages */
  padding: 10px 0; /* Increase padding for messages */
  border-radius: 20px; /* More rounded corners for messages */
  max-width: 70%; /* Limit message width for better readability */
}
.message.user {
  margin-left: auto; /* 用户消息靠右 */
  justify-content: flex-end;
}
.message.user .message-content {
  background-color: #007bff;
  color: white;
  border-radius: 20px; /* 圆角气泡 */
  padding: 10px 15px; /* 内部边距调整 */
}
.message.bot {
  justify-content: flex-start;
  flex-direction: row; /* 明确指定排列方向 */
}
.message.bot .avatar img {
  order: -1; /* 确保头像在文本的左侧 */
  margin-right: 10px; /* 头像和文本之间的空间 */
}
.message.bot .message-content {
  background-color: #e5e5ea;
  color: black; /* 文本颜色 */
  border-radius: 20px; /* 圆角气泡 */
  padding: 10px 15px; /* 内部边距调整 */
  border: 1px solid #ddd; /* 给机器人气泡添加边框 */
  order: 2; /* 确保文本在头像之后 */
  margin-right: 10px; /* 文本和头像之间的空间 */
}

.input-area {
  display: flex;
  border-top: 1px solid #ddd;
  padding: 15px; /* Increase padding for input area */
}
/* 头像样式 */
.avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  order: 1; /* 仅在.flex-direction: row生效时需要 */
}
.input-field {
  flex-grow: 1;
  width: 100%;
  padding: 15px; /* Increase padding for input field */
  border-radius: 20px; /* More rounded corners for input field */
  border: 1px solid #ccc;
  font-size: 16px; /* Increase font size for better readability */
}

.send-button {
  padding: 10px 20px;
  border-radius: 20px; /* 调整按钮的圆角 */
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.input-area {
  display: flex;
  align-items: center;
}

.input-field {
  flex-grow: 1;
  margin-right: 10px; /* 确保输入框和发送按钮之间有间距 */
}

/* 确保对话框可以完整显示的相关样式调整 */
.chat-window {
  overflow: visible; /* 确保没有溢出隐藏 */
}
.send-button:disabled {
  background-color: #ccc; /* 灰色背景 */
  cursor: not-allowed; /* 禁止符号的光标 */
}
.load {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}
.load hr {
  border: 0;
  margin: 0;
  width: 40%;
  height: 40%;
  position: absolute;
  border-radius: 50%;
  animation: spin 2s ease infinite;
}

.load :first-child { background: #19A68C; animation-delay: -1.5s; }
.load :nth-child(2) { background: #F63D3A; animation-delay: -1s; }
.load :nth-child(3) { background: #FDA543; animation-delay: -0.5s; }
.load :last-child { background: #193B48; }

@keyframes spin {
  0%, 100% { transform: translate(0); }
  25% { transform: translate(160%); }
  50% { transform: translate(160%, 160%); }
  75% { transform: translate(0, 160%); }
}
</style>